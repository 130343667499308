import React from 'react';
import myVideo from "../images/video.mp4";


function SectionFull() {
  return (

<div class="section-full">
        <div class="background-video w-background-video w-background-video-atom">
            <video
        id="36930a25-6006-5e90-73d0-a1e4934a3301-video"
        autoPlay
        loop
        muted
        playsInline
        data-wf-ignore="true"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      >
        <source src={myVideo} type="video/mp4" data-wf-ignore="true" />
        Tu navegador no soporta la etiqueta de video.
      </video>
        </div>
        <div class="base-container w-container">
            <div class="full-block-content">
                <div class="section-title-wrapper maxw">
                    <h2 id="provide-assistance" class="white-text">Los Mejores en Modernización Automotriz en Guadalajara</h2>
                    <div class="white-text mt-25">Somos el #1 en servicios premium para transformar tu coche con tecnología de vanguardia y atención al detalle</div>
                </div>
                <div class="dark-block">
                    <div class="top-block">
                        <div class="number">3+</div>
                        <div class="small-text">Años de Experiencia</div>
                    </div>
                    <div class="centered-block">
                        <div class="large-white-text">+ 200 Trabajos Realizados</div>
                        <div class="small-text">Con más de 200 vehículos transformados, nuestra experiencia y dedicación nos respaldan. </div>
                        <div class="line-vertical"></div>
                    </div>
                    <div class="centered-block">
                        <div class="large-white-text">Técnicos Profesionales</div>
                        <div class="small-text">Nuestros técnicos están altamente capacitados para transformar tu automóvil con tecnología de vanguardia. No arriesgues tu coche con gente inexperta, confía en nosotros para un trabajo de calidad</div>
                        <div class="line-vertical"></div>
                    </div>
                    <div class="centered-block">
                        <div class="large-white-text">Equipos de Alta Gama</div>
                        <div class="small-text">Utilizamos los mejores equipos y herramientas para garantizar instalaciones de la más alta calidad</div>
                        <div class="line-vertical"></div>
                    </div>
                    <div class="top-block">
                        <a href="/about-us" class="secondary-button w-button">Conoce Más</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

);
}

export default SectionFull;