import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar.js';
import Footer from './Footer';
import Home from './Home'; 
import AcercaDeNosotros from './AcercaDeNosotros'; 
import Preguntas from './Preguntas'; 
import Servicios from './Servicios';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop /> 
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/acerca-de-nosotros" element={<AcercaDeNosotros />} />
        <Route path="/preguntas" element={<Preguntas />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
