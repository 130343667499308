import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp, } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className="footer-content">

<div className="contact-container-contactanos">
  <h2 className="contactanos">Contáctanos</h2>
</div>

  <div className="footer-wrapper">
  <div className="contacts-wrap">
        <div className="contacts-block mt">
          <h3 className="red-text">Numero y Whatsapp</h3>
          <a href="tel:+525629834261" className="footer-link mb-10">
            +52 56 2983 4261
          </a>
          <a href="https://wa.me/521234567890" className="footer-link"> <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '8px' }}/>
            +52 56 2983 4261
          </a>
        </div>

        <div className="contacts-block mt">
          <h3 className="red-text">Síguenos en</h3>
          <a href="https://www.instagram.com/astrocarservice?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="footer-link"> <FontAwesomeIcon icon={faInstagram} style={{ marginRight: '8px' }}/>@astrocarservice
          </a>
          <a href="https://www.facebook.com/profile.php?id=100083698824579&mibextid=LQQJ4d" className="footer-link"> <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '8px' }}/> 
           Astro car service 
          </a>
        </div>
      </div>


      <div className="contacts-wrap">
        <div className="contacts-block mt">
          <h3 className="red-text">Ubicación</h3>
          <div className="mt-10">
            Guadalajara, Jalisco
          </div>
        </div>
        <div className="contacts-block">
          <h3 className="red-text">Horarios de atención</h3>
          <div className="mt-10">09:00 am - 08:00 pm</div>
          <div>Lunes - Domingo</div>
        </div>
      </div>
    
  </div>
  <div className="contactanos">
  <div className="center">
  <div className="footer-copyright">
  <h3>© 2024 Astro Car Service. Todos los Derechos Reservados</h3>
  {" "}
    </div>
      <a href="/templates/licensing" className="footer-copyright-link dark contactanos">
      Licencias y Certificaciones | Términos y Condiciones | Política de Privacidad
      </a>
    </div>
  </div>
</div>
  );
}

export default Footer;
