import React from 'react';
import "../styles/servicios.css";
import pantalla from "../images/trabajo4.png"
import tacometro from "../images/tacometro2.jpg"
import carplay from "../images/appleCarplay.jpg"
import camara from "../images/camara_reverza.jpeg"
import custom from "../images/custom.jpg"


function Servicios() {
  const servicios = [
    { 
      title: "Pantallas Premium", 
      description: "Instalamos pantallas de alta definición de distintos tamaños, compatibles con una amplia variedad de modelos. Contamos con opciones para BMW, Mercedes, Toyota, Mazda y otras marcas, que mejoran la conectividad y el entretenimiento en tu coche.", 
      image: pantalla 
    },
    { 
      title: "Actualización a Apple CarPlay/Android Auto", 
      description: "Ofrecemos integración de Apple CarPlay y Android Auto de dos maneras: sin adaptadores externos, con instalación interna completa, y una opción económica con adaptador externo. Ambas versiones son compatibles con todas las marcas de vehículos para una experiencia de manejo moderna y segura.", 
      image: carplay 
    },
    { 
      title: "Tacómetros Digitales", 
      description: "Moderniza el tablero de tu vehículo con tacómetros digitales de alta precisión. Disponibles para modelos de BMW, Mercedes, Toyota, Mazda y otras marcas, los tacómetros digitales añaden estilo y funcionalidad, permitiendo una visualización clara y actualizada del rendimiento del motor.", 
      image: tacometro 
    },
    {
      title: "Cámaras y Sensores de Estacionamiento",
      description: "Aumenta la seguridad de tu coche con cámaras de reversa y sensores de proximidad. Disponemos de opciones para BMW, Mercedes, Toyota, Mazda y más, con variedad de precios y software moderno que ofrece una visualización clara y alertas de proximidad para evitar accidentes al estacionar.",
      image: camara
    },
    { 
      title: "Personalización y Soluciones Custom", 
      description: "¿Buscas algo específico para tu vehículo? Ofrecemos soluciones personalizadas para adaptar cualquier instalación a tus necesidades. Desde configuraciones exclusivas hasta detalles de alta tecnología, nuestro equipo está listo para llevar tus ideas a la realidad.", 
      image: custom 
    }
];



  return (
    <div className="services-container">
      {/* Sección de Introducción */}
      <section className="services-intro">
        <h1 className="services-title">Nuestros Servicios</h1>
        <p className="services-description">
          En Astro Car Service, ofrecemos servicios de modernización automotriz diseñados para llevar tu experiencia de manejo a otro nivel.
        </p>
      </section>

      {/* Sección de Servicios */}
      <section className="services-list">
        <h2 className="services-section-title">¿Qué Ofrecemos?</h2>
        <div className="services-grid">
          {servicios.map((servicio, index) => (
            <div key={index} className="service-card">
              <img src={servicio.image} alt={servicio.title} className="service-image" />
              <div className="service-text">
                <h3 className="service-title">
                  <span className="service-icon">{servicio.icon}</span>
                  {servicio.title}
                </h3>
                <p className="service-description">{servicio.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Sección de Llamada a la Acción */}
      <section className="services-cta">
        <h2 className="cta-title">¿Interesado en mejorar tu coche?</h2>
        <p className="cta-description">
          Descubre cómo nuestros servicios pueden transformar tu vehículo y hacer que disfrutes cada momento al volante.
        </p>
      </section>
    </div>
  );
}

export default Servicios;
