import React from 'react';
import porQueAstro from "../images/porQueAstro.jpg";
import { Link } from 'react-router-dom';

function SectionMargin() {
  return (

<div class="section margin">
        <div class="base-container w-container">
            <div class="about-content-wrapper">
                <div class="about-image-left">
                    <div class="bgr-absolute"></div>
                    <div class="image-about">
                        <img src={porQueAstro} loading="lazy" alt="" class="image-cover"/>
                    </div>
                </div>
                <div class="about-description-right">
                    <h2>¿Por qué Astro Car Service?</h2>
                    <p class="paragraph-with-margin">Astro Car Service es la solución definitiva para modernizar tu automóvil. Con nuestros servicios de instalación de pantallas premium y tecnología de última generación, transformamos tu experiencia de manejo, ofreciendo comodidad y estilo incomparables</p>
                    <div class="button-block">
                        <Link to="/acerca-de-nosotros" className="primary-button w-button">Acerca De Nosotros</Link>
                        <Link to="/servicios" className="secondary-button black-text w-button">Servicios</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
}

export default SectionMargin;