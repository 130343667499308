import React from 'react';
import servicios1 from "../images/servicios1.jpg";
import servicios2 from "../images/appleCarplay.jpg";
import servicios3 from "../images/tacometro.jpg";
import { Link } from 'react-router-dom';

function Section() {
  return (
    <div className="section">
      <div className="section-line"></div>
      <div className="base-container align-top w-container">
        <div className="services-block-wrap">
          <div className="description-services">
            <h2>Servicios</h2>
            <div className="paragraph-with-margin">Ofrecemos una gama de servicios personalizables para satisfacer tus necesidades específicas</div>
            <Link to="/servicios" className="secondary-button black-text w-button">Ver todos los servicios</Link>
          </div>
          <div className="card-wrapper">
            <div className="w-dyn-list">
              <div role="list" className="services-list w-dyn-items">
                <div role="listitem" className="w-dyn-item">
                  <div className="service-content">
                    <div className="service-image w-inline-block">
                      <img loading="lazy" src={servicios1} className="image-cover" alt="Descripción de la instalación de pantallas" />
                    </div>
                    <div className="service-description">
                      <div>
                        <p className="link-cms">Instalación de Pantallas</p>
                        <p className="paragraph-with-margin">Instalamos pantallas premium con Apple CarPlay y Android Auto en marcas como BMW, Audi, Mercedes, Land Rover, Toyota y Mazda, llevando tu experiencia de manejo a otro nivel</p>
                      </div>
                      <div className="link-with-line">
                        <Link to="/servicios" className="link-collection">Más información sobre instalación de pantallas</Link>
                        <div className="red-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="w-dyn-item">
                  <div className="service-content">
                    <Link to="/servicios/paint-correction" className="service-image w-inline-block">
                      <img alt="Actualización de Apple CarPlay y Android Auto" loading="lazy" src={servicios2} className="image-cover-2"/>
                    </Link>
                    <div className="service-description">
                      <div>
                        <Link to="/servicios/paint-correction" className="link-cms">Actualización de Apple CarPlay y Android Auto</Link>
                        <p className="paragraph-with-margin">Te ofrecemos la instalación o actualización de Apple CarPlay y Android Auto en tu coche, sin necesidad de comprar una pantalla nueva</p>
                      </div>
                      <div className="link-with-line">
                        <Link to="/servicios" className="link-collection">Más información sobre actualización de Apple CarPlay</Link>
                        <div className="red-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="w-dyn-item">
                  <div className="service-content">
                    <Link to="/servicios/ceramic-coating" className="service-image w-inline-block">
                      <img alt="Tacómetros digitales" loading="lazy" src={servicios3} className="image-cover-tacometro"/>
                    </Link>
                    <div className="service-description">
                      <div>
                        <Link to="/servicios/ceramic-coating" className="link-cms">Tacómetros Digitales</Link>
                        <p className="paragraph-with-margin">Actualiza tu tablero con tacómetros digitales de alta precisión, proporcionando un estilo moderno y funcionalidad avanzada</p>
                      </div>
                      <div className="link-with-line">
                        <Link to="/servicios" className="link-collection">Más información sobre tacómetros digitales</Link>
                        <div className="red-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;