import React from 'react';
import fondoprincipal from "../images/fondoprincipal.webp";
import iconInstagram from "../images/iconInstagram.png";
import iconWhatsapp from "../images/iconWhatsapp.png";
import iconTelefono from "../images/iconTelefono.png";
import iconFacebook from "../images/iconFacebook.png";

function Banner() {
  return (
    <div className="home-banner-section">
      <div className="image-banner-absolute">
        <img
          src={fondoprincipal}
          alt="Banner principal mostrando la oferta de servicios"
          className="image-cover"
        />
      </div>
      <div className="banner-content">
        <div className="top-block-banner">
          <div className="location-block">
            <div className="icon-location">
              <img src={iconTelefono} loading="lazy" height="25px" alt="Ícono teléfono" />
            </div>
            <a href="tel:+525629834261" className="white-link">+52 56 2983 4261</a>
          </div>
          <div className="location-block">
            <div className="icon-location">
              <img src={iconWhatsapp} loading="lazy" height="25px" alt="Ícono de WhatsApp" />
            </div>
            <a href="https://wa.me/521234567890" className="white-link">Envíanos un mensaje por WhatsApp</a>
          </div>
          <div className="location-block">
            <div className="icon-location">
              <img src={iconInstagram} loading="lazy" height="25px" alt="Ícono de Instagram" />
            </div>
            <a href="https://www.instagram.com/astrocarservice?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="white-link">@astrocarservice</a>
          </div>
          <div className="location-block">
            <div className="icon-location">
              <img src={iconFacebook} loading="lazy" height="25px" alt="Ícono de Facebook" />
            </div>
            <a href="https://www.facebook.com/profile.php?id=100083698824579&mibextid=LQQJ4d" className="white-link">Síguenos en Facebook</a>
          </div>
        </div>
        <div className="bottom-block-banner">
          <div className="white-text">#1 en Pantallas Premium para Autos en Guadalajara</div>
          <h1 className="white-text margin">Actualiza tu Auto al Siguiente Nivel</h1>
          <p className="white-text banner">Moderniza tu experiencia de manejo con tecnología de última generación.</p>
          <a href="https://wa.me/521234567890" className="primary-button w-button">Agenda tu Cita</a>
        </div>
      </div>
    </div>
  );
}

export default Banner;
