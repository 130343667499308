import React, { useState } from 'react';
import "../styles/preguntas.css";

function AccordionItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className="accordion-item">
      <button
        onClick={toggleAccordion}
        className="accordion-trigger"
      >
        {question}
      </button>
      {isOpen && (
        <div className="accordion-content">
          {answer}
        </div>
      )}
    </div>
  );
}

function Preguntas() {
  const faqs = [
    {
      question: "¿Cuánto tiempo de garantía ofrecen en las instalaciones?",
      answer: "Ofrecemos una garantía de 1 año en todas nuestras instalaciones para asegurar la satisfacción y confianza de nuestros clientes."
    },
    {
      question: "¿Puedo instalar Apple CarPlay o Android Auto sin cambiar la pantalla de mi vehículo?",
      answer: "Sí, ofrecemos la opción de actualización para Apple CarPlay y Android Auto sin necesidad de reemplazar la pantalla original de tu coche."
    },
    {
      question: "¿En qué marcas de automóviles trabajan?",
      answer: "Somos especialistas en marcas como BMW, Mercedes, Land Rover, Toyota, y Mazda, entre otras."
    },
    {
      question: "¿Ofrecen servicio a domicilio?",
      answer: "Sí, contamos con un servicio premium a domicilio. Podemos realizar la instalación en tu hogar o lugar de trabajo para tu comodidad."
    },
    {
      question: "¿Cuánto tiempo toma la instalación de una pantalla o actualización?",
      answer: "Todas las instalaciones se completan en el mismo día."
    }, 
    {
      question: "¿Qué métodos de pago aceptan?",
      answer: "Aceptamos pagos en efectivo, tarjeta de crédito y débito, así como transferencias bancarias."
    },
    {
      question: "¿Cuánto tiempo toma la instalación de una pantalla o actualización?",
      answer: "Todas las instalaciones se completan en el mismo día."
    }, 
    {
      question: "¿Qué ocurre si tengo problemas con la instalación después de que se realiza?",
      answer: "Nuestra garantía de 1 año cubre cualquier ajuste o reparación que sea necesario. Contáctanos y nuestro equipo te asistirá de inmediato.Nuestra garantía de 1 año cubre cualquier ajuste o reparación que sea necesario. Contáctanos y nuestro equipo te asistirá de inmediato."
    },
    {
      question: "¿Las pantallas que instalan permiten conservar el software original del vehículo?",
      answer: "Sí, las pantallas que instalamos permiten conservar el software original del coche, además de integrar Apple CarPlay y Android Auto para una experiencia completa."
    },
    {
      question: "¿Cuáles son las ventajas de Apple CarPlay y Android Auto?",
      answer: "Apple CarPlay y Android Auto te permiten acceder a mapas, música, mensajes y llamadas sin distracciones, integrando tu teléfono al sistema de tu coche de manera segura y cómoda."
    },
    {
      question: "¿Las nuevas pantallas que instalan son táctiles?",
      answer: "Sí, nuestras pantallas son táctiles y de alta resolución, lo que facilita el acceso a las funciones de Apple CarPlay, Android Auto y el sistema original del vehículo."
    },
    {
      question: "¿Puedo actualizar solo a Apple CarPlay o Android Auto sin cambiar la pantalla?",
      answer: "Sí, ofrecemos la opción de actualizar tu sistema a Apple CarPlay o Android Auto sin necesidad de reemplazar la pantalla actual del vehículo."
    },
    {
      question: "¿La instalación de una pantalla nueva afecta otras funciones del coche?",
      answer: "No, nuestra instalación está diseñada para mantener todas las funciones originales del coche y solo añade las mejoras de la pantalla y conectividad."
    },
    {
      question: "¿Qué beneficios trae la instalación de un tacómetro digital?",
      answer: "El tacómetro digital ofrece una lectura precisa y moderna del rendimiento del motor, además de mejorar la estética y funcionalidad del tablero."
    },
    {
      question: "¿La cámara de reversa es compatible con las pantallas instaladas?",
      answer: "Sí, nuestras pantallas son compatibles con cámaras de reversa, brindando una visualización clara y segura al momento de retroceder."
    },
    {
      question: "¿Las pantallas nuevas vienen con conexión inalámbrica?",
      answer: "Sí, nuestras pantallas ofrecen conectividad inalámbrica para Apple CarPlay y Android Auto, permitiendo una experiencia de uso sin cables."
    }
    
  ];

  return (
    <div className="faq-container">
      <div className="faq-inner">
        <h1 className="faq-title">Preguntas Frecuentes</h1>
        <p className="faq-subtitle">Encuentre respuestas a las preguntas más comunes sobre nuestros productos y servicios.</p>
        {faqs.map((faq, index) => (
          <AccordionItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
}

export default Preguntas;
