import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import trabajo2 from "../images/trabajo2.png";
import trabajo3 from "../images/trabajo3.png";
import trabajo4 from "../images/trabajo4.png";
import trabajo5 from "../images/trabajo5.png";


function ClientReview() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
  };

  return (
    <div>
      <div style={{ height: '100px', width: '4px' }} className="section-line"></div>
      <div className="base-container w-container">
        <div className="slider-content">
          <h2
            className="trabajos-titulo">
            Trabajos Realizados
          </h2>
          <Slider {...settings}>
            <div className="slide">
              <div className="slide-wrapper">
                <div className="image-slide">
                  <img
                    src={trabajo2}
                    alt="Client Review 1"
                    className="image-cover"
                  />
                </div>
                <div className="slide-text-content">
                <div className="div-block-2">
                    <h3>BMW X1 2017</h3>
                  </div>
                  <p className="text-slide">
                        <ul>
                            <li>Pantalla nueva instalada</li>
                            <li>CarPlay inalámbrico</li>
                            <li>Android Auto inalámbrico</li>
                            <li>Pantalla táctil de 10.25 pulgadas</li>
                            <li>Conservación de funciones originales</li>
                        </ul>
                        </p>
                </div>
              </div>
            </div>

            <div className="slide">
              <div className="slide-wrapper">
                <div className="image-slide">
                  <img
                    src={trabajo3}
                    alt="Client Review 1"
                    className="image-cover"
                  />
                </div>
                <div className="slide-text-content">
                <div className="div-block-2">
                    <h3>BMW Serie 2</h3>
                  </div>
                  <p className="text-slide">
                        <ul>
                            <li>Cambio de pantalla</li>
                            <li>Cambio de volante</li>
                            <li>Cambio de tacómetro</li>
                            <li>Conservación de funciones originales</li>
                        </ul>
                        </p>
                </div>
              </div>
            </div>

            <div className="slide">
              <div className="slide-wrapper">
                <div className="image-slide">
                  <img
                    src={trabajo4}
                    alt="Client Review 1"
                    className="image-cover"
                  />
                </div>
                <div className="slide-text-content">
                <div className="div-block-2">
                    <h3>Mustang 2016</h3>
                  </div>
                  <p className="text-slide">
                        <ul>
                            <li>Cambio de pantalla y control de aire acondicionado digital</li>
                            <li>Sistema de control de aire acondicionado táctil</li>
                            <li>Cámara de reversa instalada</li>
                            <li>Pantalla CarPlay inalámbrica</li>
                            <li>Controles al volante funcionando</li>
                            <li>Conservación de funciones originales</li>
                        </ul>
                        </p>
                </div>
              </div>
            </div>

            <div className="slide">
              <div className="slide-wrapper">
                <div className="image-slide">
                  <img
                    src={trabajo5}
                    alt="Client Review 1"
                    className="image-cover"
                  />
                </div>
                <div className="slide-text-content">
                <div className="div-block-2">
                    <h3>BMW X2 2019</h3>
                  </div>
                  <p className="text-slide">
                        <ul>
                            <li>Cambio de pantalla de 12.3 pulgadas</li>
                            <li>Android Auto instalado</li>
                            <li>Pantalla CarPlay inalámbrica</li>
                            <li>Instalación realizada con 1 año de garantía incluido</li>
                            <li>Conservación de funciones originales</li>
                        </ul>
                        </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default ClientReview;
