import React from 'react';
import "../styles/acercaDeNosotros.css";

function AcercaDeNosotros() {
  const services = [
    { title: "Pantallas Premium", description: "Instalación de pantallas de alta definición, adaptadas a diferentes modelos de vehículos para conectividad y entretenimiento avanzados.", icon: "📺" },
    { title: "Integración Apple CarPlay/Android Auto", description: "Conecta tu smartphone al sistema de tu auto y accede de forma segura a navegación, llamadas, mensajes y entretenimiento. Contamos con opciones de instalación completa o adaptadores económicos.", icon: "📱" },
    { title: "Actualización de Tacómetros", description: "Moderniza tu tablero con tacómetros de alta precisión, compatibles con vehículos de marcas premium, para una visualización clara del rendimiento del motor.", icon: "⚙️" },
    { title: "Cámaras de Reversa y Sensores de Estacionamiento", description: "Mejora la seguridad de tu auto con cámaras y sensores integrados, que facilitan el estacionamiento y previenen accidentes.", icon: "⚙️" },
    { title: "Servicio a Domicilio", description: "Instalación profesional en tu hogar u oficina", icon: "🚗" }
  ];

  return (
    <div className="about-container">
      {/* Sección de Introducción */}
      <section className="about-intro">
        <h1 className="about-title">Acerca de Astro Car Service</h1>
        <p className="about-description">
        Astro Car Service es una empresa líder en Guadalajara dedicada a modernizar vehículos con tecnología avanzada. Nuestro enfoque es transformar tu auto en un espacio más cómodo, seguro y conectado. Nos especializamos en la integración tecnológica para automóviles de diferentes marcas como BMW, Mercedes, Toyota, Mazda y muchas más.
        </p>
      </section>

      {/* Sección de Misión y Visión */}
      <section className="about-mission-vision">
        <div className="about-card">
          <h2 className="about-card-title">Nuestra Misión</h2>
          <p className="about-card-content">
          En Astro Car Service, queremos que cada cliente disfrute de una experiencia de manejo moderna y personalizada. Nos enorgullece ofrecer un servicio de alta calidad, con tecnología de última generación que se ajusta a las preferencias y presupuestos de cada persona.

Trabajamos con precisión y dedicación para asegurar que cada proyecto esté perfectamente integrado y supere las expectativas de nuestros clientes.
          </p>
        </div>
      </section>

      {/* Sección de Servicios */}
      <section className="about-services">
        <h2 className="about-section-title">Nuestros Servicios</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <h3 className="service-title">
                <span className="service-icon">{service.icon}</span>
                {service.title}
              </h3>
              <p className="service-description">{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Sección de Llamada a la Acción */}
      <section className="about-cta">
        <h2 className="cta-title">¿Listo para modernizar tu coche?</h2>
        <p className="cta-description">
          Descubre cómo Astro Car Service puede transformar tu experiencia de manejo.
        </p>
      </section>
    </div>
  );
}

export default AcercaDeNosotros;
