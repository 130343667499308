import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../images/logo.jpg";

function Navbar() {
  return (
    <div className="navbar w-nav">
      <div className="nav-container w-container">
        <div className="nav-menu-wrapper">
          <Link to="/" aria-current="page" className="brand w-nav-brand w--current">
            <img src={logo} loading="lazy" alt="Logo" height="30"/>
          </Link>
          <nav role="navigation" className="nav-menu w-nav-menu">
            <div className="menu-wrap">
              <Link to="/acerca-de-nosotros" className="nav-item-title w-nav-link">Acerca de Nosotros</Link>
              <Link to="/preguntas" className="nav-item-title w-nav-link">Preguntas Frecuentes</Link>
              <Link to="/servicios" className="nav-item-title w-nav-link">Servicios</Link>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
