import React from 'react';
import Banner from './Banner';
import SectionMargin from './SectionMargin';
import Section from './Section';
import SectionFull from './SectionFull';
import SectionMargin2 from './SectionMargin2';
import ClientReview from './ClientReview.js';

function Home() {
  return (
    <div>
      <Banner />
      <SectionMargin />
      <Section />
      <SectionFull />
      <SectionMargin2 />
      <ClientReview />
    </div>
  );
}

export default Home;
